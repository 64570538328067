import React from "react"

const Footer = () => (
  <footer>
    <div className="container">
      <div className="columns">
        <div className="column pfr">
          <img src="/pfr.png" style={{ maxWidth: "75%" }} />
        </div>
        <div className="column footer-right">
          {/* <span>© {new Date().getFullYear()} Impla Smile</span> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "15px",
            }}
          >
            <span className="icon is-medium">
              <span className="fa-stack">
                <i className="fas fa-circle fa-stack-2x"></i>
                <i className="fas fa-envelope fa-stack-1x fa-inverse"></i>
              </span>
            </span>
            <div style={{ marginLeft: "10px" }}>
              <span>biuro@implasmile.pl</span>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="icon is-medium">
              <span className="fa-stack">
                <i className="fas fa-circle fa-stack-2x"></i>
                <i className="fas fa-phone fa-stack-1x fa-inverse"></i>
              </span>
            </span>
            <div style={{ marginLeft: "10px" }}>
              <span>22&nbsp;299 21 99</span>
              {/* <br />
              790 502 199
              <br />
              790 552 199
              <br />
              790 282 199 */}
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <div
      className="container"
      style={{ textAlign: "center", marginTop: "20px" }}
    >
      <span>© {new Date().getFullYear()} Impla Smile</span>
    </div> */}
  </footer>
)

export default Footer
