import React, { useState } from "react"
import { Link } from "gatsby"

const Header: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false)
  const [isMenuActive, setIsMenuActive] = useState(false)

  const links = [
    { title: "O nas", url: "/o-nas" },
    { title: "Produkt", url: "/produkt" },
    { title: "Katalog", url: "/katalog" },
    { title: "Szkolenia", url: "/szkolenia" },
    { title: "Clinic Video", url: "/video" },
    { title: "Kontakt", url: "/kontakt" },
  ]

  return (
    <header>
      <div id="header">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="container">
            <div className="navbar-brand">
              <Link
                aria-current="page"
                className="navbar-item"
                style={{ padding: 0 }}
                to="/"
              >
                <img src="/logo.png" alt="ImplaSmile.pl" />
              </Link>
              <a
                role="button"
                className="navbar-burger burger"
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
                onClick={() => setIsMenuActive(!isMenuActive)}
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>
            <div className={`navbar-menu${isMenuActive ? " is-active" : ""}`}>
              <div className="navbar-end">
                {links.map(link => (
                  <Link key={link.url} className="navbar-item" to={link.url}>
                    {link.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
