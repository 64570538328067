import React from "react"
import SEO from "./seo"

interface Props {
  title: string
  nofollow?: boolean
}

const InfoSection: React.FC<Props> = ({
  title,
  children,
  nofollow = false,
}) => (
  <>
    <SEO
      title={title}
      meta={nofollow ? [{ name: "robots", content: "noindex" }] : []}
    />
    <section className="hero is-fullheight-with-navbar-and-footer">
      <div className="hero-body has-white-background">
        <h1 className="is-size-1 header-with-bg">
          <div className="container">{title}</div>
        </h1>
        <div className="container">
          <div className="content is-size-5">{children}</div>
        </div>
      </div>
    </section>
  </>
)

export default InfoSection
